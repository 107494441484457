import { APIRequestResult } from "@polo/common/api/common";
import { values } from "mobx";
import { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
export const useSessionRetrieval = (retrieveFailedSession, onSessionFail, session) => {
    const interval = useRef(null);
    const history = useHistory();
    useEffect(() => {
        const sessionStatusRetrieval = async () => {
            const logs = values(session.logs);
            let lastLogUUID = undefined;
            if (logs.length) {
                lastLogUUID = logs[logs.length - 1].uuid;
            }
            let fetchFailed = false;
            try {
                const logsRequest = await session.retrieveLogsAndStatus(lastLogUUID);
                if (logsRequest.result === APIRequestResult.SUCCEEDED) {
                    interval.current = setTimeout(() => sessionStatusRetrieval(), 1000);
                }
                else {
                    fetchFailed = true;
                }
            }
            catch (e) {
                console.error(e);
                fetchFailed = true;
            }
            let redirectToDashboard = false;
            if (fetchFailed) {
                redirectToDashboard = true;
                try {
                    const failedSessionRequest = await retrieveFailedSession(session.uuid);
                    if (failedSessionRequest.result === APIRequestResult.SUCCEEDED) {
                        redirectToDashboard = false;
                        onSessionFail();
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
            if (redirectToDashboard)
                history.push(`/_polo_/`);
        };
        sessionStatusRetrieval();
        return () => {
            if (interval.current)
                clearTimeout(interval.current);
        };
    }, []);
};
export const useFailingSessionRetrieval = (sessino) => {
};
